import {api} from "@/api";

const sms_url = (root, filter, page) => {
    var url = root;

    if (page) url = url + "&page=" + page;

    if (filter.value !== undefined && filter.value.propertyId)
     url = url + "&propertyId=" + filter.value.propertyId;

    url = url.replace("scheduled-sms?&", "scheduled-sms?");

    return url;
};

export const fetchManagerScheduledSms = async(filter, page) => {
    return await api
        .get(sms_url("scheduled-sms?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
};