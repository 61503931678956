<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <div class="card">
          <div class="pb-0 card-header text-start">
            <h4 class="font-weight-bolder">Settings</h4>
            <p class="mb-0">Update your business configuration below</p>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-4 mx-auto">
                <el-form
                  ref="profileFormRef"
                  :model="profileForm"
                  
                  label-width="auto"
                  class="demo-ruleForm"
                  size="large"
                  status-icon
                  :scroll-to-error="true"
                  label-position="top"
                  :hide-required-asterisk="true"
                >
              
                  <div class="">
                    <el-form-item label="Business Name" prop="business_name">
                      <el-input v-model="profileForm.name" />
                    </el-form-item>
                  </div>
                  <div class="">
                    <el-form-item
                      label="Business Email Address"
                      prop="business_email"
                    >
                      <el-input v-model="profileForm.email" />
                    </el-form-item>
                  </div>
                  <div class="">
                    <el-form-item
                      label="Business Address"
                      prop="business_address"
                    >
                      <el-input
                        v-model="profileForm.address"
                        placeholder="P.O Box. 0000, Nairobi,Kenya"
                      />
                    </el-form-item>
                  </div>
                  <div class="">
                    <el-form-item label="Business Phone No" prop="business_phone">
                      <el-input v-model="profileForm.phone" />
                    </el-form-item>
                  </div>
                  <div class="">
                    <el-form-item label="Payment Receipt Tag Line" prop="payment_receipt_tagline">
                      <el-input v-model="profileForm.payment_receipt_tagline" />
                    </el-form-item>
                  </div>

                  <el-button
                    type="success"
                    class="px-5 d-block my-3 mx-auto"
                    :loading="isLoading"
                    @click="submitForm(profileFormRef, user.id)"
                    v-if="hasPermission('update.business_settings')"
                    >
                    Update Settings
                   </el-button>
                  </el-form>
                </div>

                <div class="col-md-4 mx-auto">
                  <div class="p-3 card-body mt-5">
                    <el-form
                    ref="logoFormRef"
                    :model="logoForm"
                    :rules="rules"
                    label-width="120px"
                    size=""
                    status-icon
                    :scroll-to-error="true"
                    label-position="top"
                    :hide-required-asterisk="true"
                  >
                
                <el-upload
                class="upload-demo"
                drag
                accept=".jpeg,.jpg,.png,.PNG, .JPEG,.JPG,.gif"
                :before-upload="handleBeforeUpload"
                :on-change="addAttachment"
                :auto-upload="false"
                :on-remove="removeFile"
                action=""
                :file-list="fileList"
                >
                <el-icon class="el-icon--upload"><upload-filled /></el-icon>
                <div class="el-upload__text">
                    Drop image here or <em>click to upload</em>
                </div>
                <template #tip>
                    <div class="el-upload__tip">
                    upload an Image
                    </div>
                </template>
                </el-upload>
                <div class="d-flex justify-content-between">
                  <div class="text-center mt-5">
                    <el-button
                     type="success"
                      class="my-4 mb-2 text-white"
                      :loading="loading2"
                      @click.prevent="submitLogoForm(logoFormRef)"
                  >Update Logo</el-button >
                </div> 
                <div class="text-center mt-5">
                    <el-button
                     type="success"
                      class="my-4 mb-2"
                      :loading="loading3"
                      @click.prevent="removeLogo()"
                  >Remove Logo</el-button >
                </div> 
                </div>
                </el-form>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</template>

<script>
import { getUserDetails, updateProfile, deleteLogo, updateLogo } from "@/api/property-manager/settings.js";
import Loader from "@/components/Loader.vue";
import { reactive, ref } from "vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
// import moment from "moment";
import { ElNotification } from "element-plus";
const body = document.getElementsByTagName("body")[0];
const profileFormRef = ref();
const isLoading = ref(false);
const user = ref({});
const isUpdatingProfile = ref(false);
const loading2 = ref(false)
const loading3 = ref(false)


export default {
  components: { Loader },

  setup(){
    const profileForm = reactive({
      email: "",
      phone: "",
      payment_receipt_tagline: "",
      address: "",
      name: "",

    });

    const fetchDetails = async () => {
      isLoading.value = true;
      try {
        const response = await getUserDetails();
        let data = response.data;
        console.log("the dtaa", data);
        user.value = response.data;

        profileForm.name = data.name || "";
        profileForm.email = data.email || "Unavailable";
        profileForm.phone = data.primaryPhone || "Unavailable";
        profileForm.payment_receipt_tagline = data.payment_receipt_tagline || "Unavailable";
        profileForm.address = data.address || "Unavailable";
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const submitForm = async (formEl, id) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          isLoading.value = true;
          isUpdatingProfile.value = true;
          const formData = new FormData();
          formData.append("name", profileForm.name);
          formData.append("phone", profileForm.phone);
          formData.append("payment_receipt_tagline", profileForm.payment_receipt_tagline);
          formData.append("address", profileForm.address);
          formData.append("email", profileForm.email);
          formData.append("_method", "PUT");
          console.log(formData)

          updateProfile(formData, id)
            .then((data) => {
              if (data.success) {
                ElNotification({
                  title: "Success",
                  message: data.message,
                  type: "success",
                });
              } else {
                let error = Object.values(data.errors);

                error.forEach((err) =>
                  ElNotification({
                    title: "Error",
                    message: err[0],
                    type: "error",
                  })
                );
              }
            })
            .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
            )
            .finally(() => {
              isUpdatingProfile.value = false;
              isLoading.value = false;
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    const removeLogo = async () => {
        loading3.value = true
        await deleteLogo()
              .then((response) => {
                console.log(response.data);
                if (response.data.success) {
                  ElNotification({
                    title: "Success",
                    message: response.data.message,
                    type: "success",
                  });
                }else if (response.data.error) {
                  ElNotification({
                    title: 'Error',
                    message: response.data.error,
                    type: 'error',
                  });
              }  else {
                  Object.values(response.data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                  );
                }
              })
              .catch((err) =>
                  console.log(err),
              )
              .finally(() => {
                loading3.value = false
          });
          };



      const currentFile = ref(null);
      const fileList = ref([]);

      const handleBeforeUpload = (file) => {
      const fileType = file.type;
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif']; // Add more image types if needed
      const isValidImageType = validImageTypes.includes(fileType);

      if (!isValidImageType) {
        this.$message.error('Please upload a valid image file (JPEG, PNG, GIF, etc.).');
        return false;
      }

      return true;
    };
    const formData = new FormData();
    const addAttachment = (file) => {
      console.log(file);
      formData.append('file', file.raw);
      fileList.value.push(file);
      currentFile.value = file;
    };

    const removeFile = () => {
      if (currentFile.value) {
        console.log('Removing:', currentFile.value);
        fileList.value = fileList.value.filter((file) => file !== currentFile.value);
        currentFile.value = null;
      }
    };

    const submitLogoForm = async () => {
      if (currentFile.value) {
        loading2.value = true
        await updateLogo(formData)
          .then((data) => {
            console.log('data', data);
            if (data.success) {
              ElNotification({
                title: 'Success',
                message: data.message,
                type: 'success',
              });
            } else if (data.error) {
              ElNotification({
                title: 'Error',
                message: data.error,
                type: 'error',
              });
            } else {
              Object.values(data.errors).forEach((val) =>
                ElNotification({
                  title: 'Error',
                  message: val[0],
                  type: 'error',
                })
              );
            }
          })
          .catch((err) =>
            ElNotification({
              title: 'Error',
              message: err.message,
              type: 'error',
            })
          )
          .finally(() => {
            removeFile();
            loading2.value = false
          });
      }else{
        ElNotification({
                title: 'Error',
                message: 'Please upload an image',
                type: 'error',
              });
      }
    };



    return {
      fetchDetails,
      profileFormRef,
      user,
      profileForm,
      isLoading,
      isUpdatingProfile,
      submitForm,
      removeLogo,
      loading2,
      loading3,
      handleBeforeUpload,
      addAttachment,
      submitLogoForm
    }
  },
  data(){ 
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));
    return {
      userPermissions: storedPermissions || [],

    };
  },
  methods: {
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
  mounted() {
    this.$store.state.isAbsolute = false;
    setNavPills();
    setTooltip();
  },
  created() {
    this.fetchDetails();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  },
}
</script>