
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div v-if="isLoading"><Loader /></div>
        <scheduled-sms-table
            :sms="sms"
            :refresh="fetchData"
            v-else
        />
      </div>
    </div>
  </div>
</template>

<script>
import ScheduledSmsTable from "../components/property-manager/ScheduledSmsTable.vue";
import Loader from "./../components/Loader.vue";
import { fetchManagerScheduledSms } from "../../api/property-manager/scheduledSms";
import { ElLoading } from "element-plus";
import { ref } from "vue";

const isLoading = ref(false);
const sms = ref([]);

export default {
  name: "ScheduledSms",
  components: {
      ScheduledSmsTable,
      Loader,
  },
  setup() {
    const fetchData = async (filter, page) => {
      isLoading.value = true;
      let entity_params = {};
      try {
        if (filter === undefined) {
          entity_params = ref({});
        } else {
          entity_params = filter;
        }
        const response = await fetchManagerScheduledSms(entity_params, page);
        sms.value = response.data;
        console.log('response is',sms.value)
      } catch (error) {
        console.log(error);
      } finally {
        isLoading.value = false;
      }
    };

    const fullScreenLoader = () => {
      ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    };

    return {
      fullScreenLoader,
      fetchData,
      sms,
      isLoading,
   
    };
  },
  mounted() {
    this.fetchData();
    
  },
};
</script>

