<template>
  <el-menu
      default-active="2"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose"
  >
    <el-menu-item index="2">
      <i class="fa fa-th text-primary text-sm opacity-10 me-2"></i>
      <router-link v-if="userType === 'manager'" to="/dashboard-default" class="text-sm"
      >Dashboard </router-link>
      <router-link v-if="userType === 'admin'"  to="/admin-dashboard" class="text-sm"
      >Dashboard </router-link>
      <router-link v-if="userType === 'landlord'"  to="/landlord-dashboard" class="text-sm"
      >Dashboard </router-link>
    </el-menu-item>
    <el-sub-menu
        index="12"
        v-if="hasPermission('manage.property_managers') && userType === 'admin'"
    >
      <template #title>
        <i class="fa fa-address-book-o text-sm opacity-10 text-primary me-2" aria-hidden="true"></i>
        <span>Property Managers</span>
      </template>
      <el-menu-item-group title="">
        <router-link to="/property-managers" class="text-sm">
          <el-menu-item index="12-1">
            <i class="fa fa-th-list text-sm opacity-10 text-primary me-2" aria-hidden="true"></i>
            Managers List</el-menu-item></router-link>
      </el-menu-item-group>
    </el-sub-menu>
    <el-sub-menu
        index="1"
        v-if="
                hasPermission('manage.landlords') ||
                hasPermission('manage.tenants')
          "
    >
      <template #title>
        <i class="fa fa-users text-sm opacity-10 text-primary me-2" aria-hidden="true"></i>
        <span>System Users</span>
      </template>
      <el-menu-item-group title="">
        <router-link to="/tenants" class="text-sm">
          <el-menu-item index="1-1">
            <i class="fa fa-user text-sm opacity-10 text-primary me-2" aria-hidden="true"></i>
            Tenants</el-menu-item></router-link>
        <router-link to="/landlords"><el-menu-item index="1-2">
          <i class="fa fa-user-plus text-sm opacity-10 text-primary me-2" aria-hidden="true"></i>
          Landlords</el-menu-item></router-link>
      </el-menu-item-group>
    </el-sub-menu>
    <el-sub-menu index="3" v-if="hasPermission('manage.properties')">
      <template #title>
        <i class="fa fa-home text-sm opacity-10 text-primary me-2"></i>
        <span>Properties</span>
      </template>
      <el-menu-item-group title="">
        <router-link to="/properties" v-if="hasPermission('view.properties')">
          <el-menu-item index="3-1" >
            <i class="fa fa-building text-sm opacity-10 text-primary me-2"></i>
            Properties</el-menu-item></router-link>
        <router-link to="/closed-leases">
          <el-menu-item index="3-2">
            <i class="fa fa-ban text-sm opacity-10 text-primary me-2"></i>
            Closed Leases</el-menu-item></router-link>
      </el-menu-item-group>
    </el-sub-menu>
    <el-sub-menu index="4" v-if="hasPermission('manage.payments')" >
      <template #title>
        <i class="fa fa-money text-sm opacity-10 text-primary me-2"></i>
        <span>Payments</span>
      </template>
      <el-menu-item-group title="">
        <router-link to="/manager/payments"><el-menu-item index="4-2">
          <i class="fa fa-credit-card-alt text-sm opacity-10 text-primary me-2"></i>
          Payments</el-menu-item></router-link>
        <router-link to="/payments/unallocated"><el-menu-item index="4-3" >
          <i class="fa fa-credit-card text-sm opacity-10 text-primary me-2"></i>
          Unallocated Payments</el-menu-item></router-link>
          <router-link to="/etims"><el-menu-item index="4-4" >
          <i class="fa fa-credit-card text-sm opacity-10 text-primary me-2"></i>
          eTims</el-menu-item></router-link>
      </el-menu-item-group>
    </el-sub-menu>
    <el-sub-menu
        v-if="hasPermission('manage.accounts')"
        index="5">
      <template #title>
        <i class="fa fa-address-book text-sm opacity-10 text-primary me-2"></i>
        <span>Reports</span>
      </template>
      <el-menu-item-group title="">
        <router-link to="/manager/accounting/reports"><el-menu-item index="5-1">
          <i class="fa fa-book text-sm opacity-10 text-primary me-2"></i>
          Accounts</el-menu-item></router-link>
        <router-link to="/manager/payments/reports"><el-menu-item index="5-2"  v-if="hasPermission('manage.payments')">
          <i class="fa fa-calculator text-sm opacity-10 text-primary me-2"></i>
          Payments</el-menu-item></router-link>
        <router-link to="/manager/lease/reports"><el-menu-item index="5-3"  v-if="hasPermission('manage.leases')">
          <i class="fa fa-address-card-o text-sm opacity-10 text-primary me-2"></i>
          Leases</el-menu-item></router-link>
          <router-link to="/manager/tenant/statement"><el-menu-item index="5-1">
          <i class="fa fa-book text-sm opacity-10 text-primary me-2"></i>
          Tenant Statement</el-menu-item></router-link>
      </el-menu-item-group>
    </el-sub-menu>
    <el-sub-menu
        v-if="hasPermission('manage.communications')"
        index="6">
      <template #title>
        <i class="fa fa-server text-sm opacity-10 text-primary me-2"></i>
        <span>Services</span>
      </template>
      <el-menu-item-group title="">
        <router-link to="/communications"><el-menu-item index="6-1">
          <i class="fa fa-comments opacity-10 text-primary me-2"></i>
          Communications </el-menu-item></router-link>
        <!-- <router-link to="/service-request"><el-menu-item index="6-2">
          <i class="fa fa-pencil-square-o text-sm opacity-10 text-primary me-2"></i>
          Requests </el-menu-item></router-link> -->
        <router-link to="/outgoing-sms"><el-menu-item index="6-3">
          <i class="fa fa-comment text-sm opacity-10 text-primary me-2"></i>
          Outgoing Messages </el-menu-item></router-link>
        <router-link to="/scheduled-sms"><el-menu-item index="6-3">
          <i class="fa fa-comment text-sm opacity-10 text-primary me-2"></i>
          Scheduled SMS </el-menu-item></router-link>  
      </el-menu-item-group>
    </el-sub-menu>
    <el-sub-menu index="8" v-if="hasPermission('manage.system_users')">
      <template #title>
        <i class="fa fa-user-circle opacity-10 text-primary me-2"></i>
        <span>Managerial Users</span>
      </template>
      <el-menu-item-group title="">
        <router-link to="/roles"><el-menu-item index="7-2" v-if="hasPermission('view.roles')">
          <i class="fa fa-check text-sm opacity-10 text-primary me-2"></i>
          Roles And Permission</el-menu-item></router-link>
      </el-menu-item-group>
      <el-menu-item-group title="">
        <router-link to="/managerial-users"><el-menu-item index="8-1" v-if="hasPermission('view.system_users')">
          <i class="fa fa-user-secret opacity-10 text-primary me-2"></i>
          Users </el-menu-item></router-link>
      </el-menu-item-group>
    </el-sub-menu>
    <el-sub-menu
        index="9"
        v-if="
                hasPermission('manage.business_incomes') ||
                hasPermission('manage.business_expenses')
          "
    >
      <template #title>
        <i class="fa fa-handshake-o opacity-10 text-primary me-2"></i>
        <span>Business</span>
      </template>
      <el-menu-item-group title="">
        <router-link to="/business-income"><el-menu-item index="9-1">
          <i class="fa fa-plus opacity-10 text-primary me-2"></i>
          Income</el-menu-item></router-link>
      </el-menu-item-group>
      <el-menu-item-group title="">
        <router-link to="/business-expenses"><el-menu-item index="9-2">
          <i class="fa fa-minus-circle opacity-10 text-primary me-2"></i>
          Expenses</el-menu-item></router-link>
      </el-menu-item-group>
    </el-sub-menu>
    <el-sub-menu index="10" v-if="hasPermission('manage.statistics')">
      <template #title>
        <i class="fa fa-line-chart opacity-10 text-primary me-2"></i>
        <span>Analytics</span>
      </template>
      <el-menu-item-group title="">
        <router-link to="/manager-stats"><el-menu-item index="10-1">
          <i class="fa fa-bar-chart opacity-10 text-primary me-2"></i>
          Statistics</el-menu-item></router-link>
        <router-link to="/manager-logs"><el-menu-item index="10-2" >
          <i class="fa fa-list opacity-10 text-primary me-2"></i>
          Logs</el-menu-item></router-link>
      </el-menu-item-group>
    </el-sub-menu>

    <el-sub-menu index="11" v-if="hasPermission('manage.invoices')">
      <template #title>
        <i class="fa fa-calendar-o opacity-10 text-primary me-2"></i>
        <span>Billing</span>
      </template>
      <el-menu-item-group title="">
        <router-link to="/invoices"><el-menu-item index="11-1">
          <i class="fa fa-calculator opacity-10 text-primary me-2"></i>
          Invoices</el-menu-item></router-link>
      </el-menu-item-group>
    </el-sub-menu>

    <hr class="my-3 horizontal dark" />
    <el-menu-item index="2" v-if="hasPermission('manage.business_settings')">
      <i class="fa fa-cog text-primary text-sm opacity-10 me-2"></i>
      <router-link to="/settings" class="text-sm">Business Settings</router-link>
    </el-menu-item>

  </el-menu>
</template>
<script>
export default {
  name: "ManagerNav",

  props: {
    cardBg: String,
  },
  data() {
    const storedPermissions = JSON.parse(localStorage.getItem("permissionlist"));

    const userType = localStorage.getItem("userType");

    return {
      title: "PropertyManagement",
      controls: "dashboardsMain",
      isActive: "active",
      userType: userType,
      userPermissions: storedPermissions || [],

    };
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    hasPermission(slug) {
      return this.userPermissions.includes(slug);
    },
  },
}
</script>
<style lang="scss"  scoped>
$color-emperor: #4f4f4f;
$color-mine-shaft: #333333;
$color-bondi-blue: #344767;
$left-spacing: 20px;
.collapsible-menu {
  font-size: 14px;
  padding: 0;
  margin: 0;
  overflow: hidden;

  .check {
    position: absolute;
    user-select: none;
    pointer-events: none;
    opacity: 0;

    &:checked {
      ~ ul {
        opacity: 1;
        max-height: 9999em;
        pointer-events: all;
        transition: opacity 0.15s ease-in-out;

        > li {
          margin: 12px 0;
        }
      }

      + label {
        > .icon-wrapper {
          > .icon-angle {
            transform: rotate(90deg);
          }
        }
      }
    }
  }

  ul,
  li {
    list-style: none;
  }

  ul {
    opacity: 0;
    max-height: 0;
    pointer-events: none;
    padding: 0;
    overflow: hidden;

    li {
      margin: 0;
      transition: 0.15s ease-out;
      padding-left: $left-spacing;
    }
  }

  // collapsible menu
  label {
    font-weight: 600;
    color: #344767;
    user-select: none;
    cursor: pointer;
    transition: 0.15s ease-in-out;
    margin-bottom: 0;
    width: 100%;
    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 1rem;

      .icon-angle {
        transform-origin: center;
        transition: 0.15s ease-in-out;
      }
    }
  }

  > li {
    &.active {
      overflow: hidden;
      box-shadow: none;
      color: #344767;

      > label {
        background-color: #f6f9fc;
        border-radius: 0.5rem;
      }
    }

    &:not(.active) {
      .check {
        &:checked {
          ~ ul {
            margin-top: -8px;
            margin-left: 15px;
          }
        }
      }
    }
  }
}

a {
  padding-left: 0 !important;
}
</style>