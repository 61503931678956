<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div
      class="card"
      :style="{ 'max-height': tenantsDropdown ? '530px' : '480px' }"
      @click.stop
    >
      <div class="card-header">
        <i class="fa fa-home text-primary me-2" />Create Manual Payment
      </div>
      <hr class="horizontal dark m-0" />
      <el-form
        ref="paymentFormRef"
        :model="paymentForm"
        :rules="rules"
        label-width="120px"
        size=""
        status-icon
        :scroll-to-error="true"
        label-position="top"
        :hide-required-asterisk="true"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mb-3" v-if="tenantsDropdown === true">
              <label class="el-form-item__label">Tenant</label>
              <el-form-item prop="tenant">
                <el-select-v2
                  v-model="paymentForm.tenant"
                  filterable
                  :options="tenants"
                  placeholder="Please select a tenant"
                  style="width: 100%"
                />
              </el-form-item>
            </div>

            <div class="col-md-12 mb-3">
              <el-form-item label="Reference No." prop="reference_no">
                <el-input
                  v-model="paymentForm.reference_no"
                  placeholder="Please input reference number"
                  clearable
                />
              </el-form-item>
            </div>
            <div class="col-md-12 mb-3">
              <el-form-item label="Amount" prop="amount">
                <el-input
                  type="number"
                  v-model="paymentForm.amount"
                  placeholder="Please input amount"
                  clearable
                />
              </el-form-item>
            </div>

            <div class="col-md-12 mb-3">
              <el-form-item label="Date" prop="date">
                <el-date-picker
                  style="width: 100%"
                  v-model="paymentForm.date"
                  type="datetime"
                  placeholder="Select date and time"
                  :size="size"
                />
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="card--footer">
          <button
            class="btn mb-0 btn-danger btn-xs null null"
            @click="$emit('close-modal')"
          >
            Cancel
          </button>
          <el-button
            type="success"
            :loading="isLoading"
            @click="submitForm(paymentFormRef)"
            >Pay</el-button
          >
        </div>
      </el-form>
    </div>
    <div class="close" @click="$emit('close-modal')">
      <i class="fa fa-times" />
    </div>
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import { ElNotification } from "element-plus";
import { fetchLeasedManagerTenants } from "@/api/property-manager/tenants";
import { makeManualPayment } from "@/api/property-manager/payments";
import { postToKra } from "@/api/kra";

const paymentFormRef = ref();
const isLoading = ref(false);
const propertyManagerId = localStorage.getItem("propertyManagerId");
const tenants = ref([]);
const unitId = ref();
export default {
  name: "payments-form",

  props: {
    property: {
      type: Object,
      required: false,
    },
    tenantsDropdown: {
      type: Object,
      required: true,
    },
    unitId: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    const paymentForm = reactive({
      id: props?.property?.unitId || 0,
      reference_no: props?.property?.reference_no || "",
      amount: props?.property?.amount || "",
      date: props?.property?.date || "",
      tenant: "",
      unitId: props?.unitId || "",
    });

    const rules = reactive({
      reference_no: [
        {
          required: true,
          message: "Please input reference number",
          trigger: ["blur", "change"],
        },
      ],
      amount: [
        {
          required: true,
          message: "Please input amount",
          trigger: ["blur", "change"],
        },
      ],
      tenant: [
        {
          required: true,
          message: "Please select a tenant",
          trigger: ["blur", "change"],
        },
      ],
      date: [
        {
          required: true,
          message: "Please select date",
          trigger: ["blur", "change"],
        },
      ],
    });

    const dateTransformer = (dateString) => {
      var date = new Date(dateString);

      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);
      var hours = ("0" + date.getHours()).slice(-2);
      var minutes = ("0" + date.getMinutes()).slice(-2);
      var seconds = ("0" + date.getSeconds()).slice(-2);

      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    };

    const submitForm = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid, fields) => {
        if (valid) {
          if (props?.unitId) {
            unitId.value = props?.unitId;
          } else {
            unitId.value = paymentForm.tenant;
          }

          console.log(dateTransformer(paymentForm.date));

          isLoading.value = true;
          const formData = new FormData();
          formData.append("reference_number", paymentForm.reference_no);
          formData.append("date", dateTransformer(paymentForm.date));
          formData.append("amount", paymentForm.amount);
          formData.append("unitId", unitId.value);
          formData.append("managerId", propertyManagerId);

          makeManualPayment(formData)
            .then((data) => {
              if (data.success) {
                console.log(postToKra(data.data));

                ElNotification({
                  title: "Success",
                  message: data.message,
                  type: "success",
                });
                emit("refresh");
                emit("refresh-unit-payment");
                emit("close-modal");
              } else if (data.error) {
                ElNotification({
                  title: "Error",
                  message: data.error,
                  type: "error",
                });
              } else {
                Object.values(data.errors).forEach((val) =>
                  ElNotification({
                    title: "Error",
                    message: val[0],
                    type: "error",
                  })
                );
              }
            })
            .catch((err) => console.log(err))
            .finally(() => {
              isLoading.value = false;
            });
        } else {
          console.log("error submit!", fields);
        }
      });
    };

    return {
      paymentForm,
      submitForm,
      rules,
      paymentFormRef,
      isLoading,
      props,
      tenants,
    };
  },

  created() {
    this.fetchTenantList();
  },
  methods: {
    async fetchTenantList() {
      try {
        const response = await fetchLeasedManagerTenants();

        const tempTenants = response.data.map((tenant) => ({
          value: `${tenant.leases[0]?.unit?.unitId}`,
          label: `${
            tenant.firstName +
            " " +
            tenant.lastName +
            " - " +
            tenant.leases[0]?.unit?.unitCode
          }`,
        }));

        tenants.value = tempTenants;
        console.log(tenants);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.card {
  margin-top: 5%;
  outline: 0;
  border-radius: 5px;
  max-width: 500px;
  max-height: 480px;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
}
.card-body {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}

.card-header {
  border-bottom: 1px solid #e9ecef;
  padding: 1.2rem !important;
}
.card--footer {
  display: flex;
  justify-content: space-between;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
.close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: 0.75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.close i {
  font-size: 15px;
}
input {
  border-radius: 6px;
}
</style>
