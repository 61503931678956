import {api} from "@/api";
const sms_url = (root, filter, page) => {
    var url = root;

    if (filter.value != undefined && filter.value.search_query)
        url =
            url +
            "&searchFields=sms:like;name:like;phone:like;&search=" +
            filter.value.search_query;

    if (page) url = url + "&page=" + page;

    if (filter.value != undefined && filter.value.phone)
        url = url + "&phone=" + filter.value.phone;

    if (filter.value != undefined && filter.value.date_range)
         url = url + "&date_range=" + filter.value.date_range;

    url = url.replace("outgoing-blasts?&", "outgoing-blasts?");

    return url;
};
export const fetchManagersSms = async(filter, page) => {
    let response = await api
        .get(sms_url("outgoing-blasts?", filter, page))
        .then((response) => response.data)
        .catch((e) => e.response.data);
    return response;
};
