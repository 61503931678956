import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/property-manager/Dashboard.vue";
import Tenants from "../views/property-manager/Tenants.vue";
import Landlords from "../views/property-manager/Landlords.vue";
import PropertyDetails from "../views/property-manager/PropertyDetails.vue";
import UnitDetails from "../views/property-manager/UnitDetails.vue";
import Roles from "../views/property-manager/Roles.vue";
import RolesForm from "../views/property-manager/RolesForm.vue";
import Communications from "../views/property-manager/Communications.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/SignUp.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Signin from "../views/SignIn.vue";
import ClosedLeases from "../views/property-manager/ClosedLeases.vue";
import LandlordClosedLeases from "../views/landlord/ClosedLeases.vue";
import Properties from "../views/property-manager/Properties.vue";
import ViewClosedLease from "../views/property-manager/ViewClosedLease.vue";
import ManagerPayments from "../views/property-manager/ManagerPayments.vue";
import ResetPassword from "../views/ResetPassword.vue";
import EmailVerification from "../views/EmailVerification.vue";
import NotFound from "../views/404/NotFound.vue";
import Welcome from "../views/Welcome.vue";
import SmsPasswordTable from "../views/components/property-manager/SmsPasswordTable.vue";
import ComposeBlastSms from "../views/components/property-manager/ComposeBlastSms.vue";
import BlastInvoiceSms from "../views/components/property-manager/BlastInvoiceSms.vue";
import WaterBillSms from "../views/components/property-manager/WaterBillSms.vue";
import ScheduledSmsForm from "../views/components/property-manager/ScheduledSms.vue";
import UnallocatedPayments from "../views/property-manager/UnallocatedPayments.vue";
import Settings from "../views/property-manager/Settings.vue";
import ViewPropertyDrawer from "../components/Drawers/ViewPropertyDrawer.vue";
import ViewUnitDrawer from "../components/Drawers/ViewUnitDrawer.vue";
import AccountingReport from "../views/property-manager/AccountingReport.vue";
import PaymentsReport from "../views/property-manager/PaymentsReport.vue";
import LeaseReport from "../views/property-manager/LeaseReport.vue";
import ManegerialUsers from "../views/property-manager/ManegerialUsers.vue";
import BusinessIncome from "../views/property-manager/BusinessIncome.vue";
import BusinessExpense from "../views/property-manager/BusinessExpense.vue";
import Invoices from "../views/property-manager/Invoices.vue";
import StatisticalCharts from "../views/property-manager/StatisticalCharts.vue";
import ServiceRequest from "../views/property-manager/ServiceRequest.vue";
import Logs from "../views/property-manager/Logs.vue";
import LandlordView from "../views/components/property-manager/LandlordView.vue";
import ManegerialUser from "../views/property-manager/ManegerialUser.vue";
import ResetPasswordLink from "../views/ResetPasswordLink.vue";
import OutgoingSms from "../views/property-manager/OutgoingSms.vue";
import ScheduledSms from "../views/property-manager/ScheduledSms.vue";
import TotalExpectedAmount from "../views/property-manager/TotalExpectedAmount.vue";
import AdminDashboard from "../views/admin/AdminDashboard.vue";
import PropertyManagers from "../views/admin/PropertyManagers.vue";
import AdminLandlords from "../views/admin/AdminLandlords.vue";
import AdminTenants from "../views/admin/AdminTenants.vue";
import AdminProperties from "../views/admin/AdminProperties.vue";
import AdminPayments from "../views/admin/AdminPayments.vue";
import AdminUnallocatedPayments from "../views/admin/AdminUnallocatedPayments.vue";
import AdminInvoices from "../views/admin/AdminInvoices.vue";
import AdminInvoicesPayments from "../views/admin/AdminInvoicesPayments.vue";
import PropertyManagerDetails from "../views/components/admin/PropertyManagerDetails.vue";
import LandingPage from "../views/LandingPage.vue";
import TotalMonthBalance from "../views/property-manager/TotalMonthBalance.vue";
import ETims from "../views/property-manager/ETims.vue";
import RentInvoiceTable from "../views/components/property-manager/RentInvoiceTable.vue";
import PaymentInvoiceTable from "../views/components/property-manager/PaymentInvoiceTable.vue";
import LandlordDashboard from "../views/landlord/LandlordDashboard.vue";
import LandlordTenants from "../views/landlord/LandlordTenants.vue";
import LandlordProperties from "../views/landlord/LandlordProperties.vue";
import LandlordPayments from "../views/landlord/LandlordPayments.vue";
import PaymentPage from "@/views/PaymentPage.vue";
import TenantStatement from "../views/property-manager/TenantStatement.vue";
import BookDemo from "../views/BookDemo.vue";
import ReportView from "../views/property-manager/ReportView.vue";

const routes = [
  {
    path: "/:pathMatch(.*)",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/",
    name: "/",
    component: LandingPage,
  },
  {
    path: "/book-demo",
    name: "Book Demo",
    component: BookDemo,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/auth/reset-password/:id",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/auth/email-verification/:id",
    name: "Email Verification",
    component: EmailVerification,
  },
  {
    path: "/auth/reset-password-link/:id",
    name: "Reset Password Form",
    component: ResetPasswordLink,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/uploads",
    name: "Uploads",
    component: Signup,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/",
    meta: { requiresAuth: true },
    children: [
      {
        path: "/admin-dashboard",
        name: "Admin Dashboard",
        component: AdminDashboard,
      },
      {
        path: "/landlord-dashboard",
        name: "Landlord Dashboard",
        component: LandlordDashboard,
      },
      {
        path: "/dashboard-default",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/subscription-page",
        name: "PaymentPage",
        component: PaymentPage,
      },
      {
        path: "/tenants",
        name: "Tenants",
        component: Tenants,
      },
      {
        path: "/landlord-tenants",
        name: "Landlord Tenants",
        component: LandlordTenants,
      },
      {
        path: "/managerial-users",
        name: "Managerial Users",
        component: ManegerialUsers,
      },
      {
        path: "/managerial-user/:id",
        name: "Managerial User View",
        component: ManegerialUser,
      },
      {
        path: "/property/:id",
        name: "Property Details",
        component: PropertyDetails,
      },
      {
        path: "/property-month-collection/:id",
        name: "Property Monthly Collection",
        component: PropertyDetails,
      },
      {
        path: "/property-month-balance/:id",
        name: "Property Monthly Balance",
        component: TotalMonthBalance,
      },
      {
        path: "/unit/:id",
        name: "Unit Details",
        component: UnitDetails,
      },
      {
        path: "/unit/:id/details",
        name: "More On Unit",
        component: ViewUnitDrawer,
      },
      {
        path: "/landlords",
        name: "Landlords",
        component: Landlords,
      },
      {
        path: "/admin/landlords",
        name: "admin landlords",
        component: AdminLandlords,
      },
      {
        path: "/admin/properties",
        name: "admin properties",
        component: AdminProperties,
      },
      {
        path: "/landlord-properties",
        name: "landlord properties",
        component: LandlordProperties,
      },
      {
        path: "/admin/tenants",
        name: "admin tenants",
        component: AdminTenants,
      },
      {
        path: "/admin/payments",
        name: "admin payments",
        component: AdminPayments,
      },
      {
        path: "/landlord-payments",
        name: "landlord payments",
        component: LandlordPayments,
      },
      {
        path: "/admin/unallocated",
        name: "unallocated payments",
        component: AdminUnallocatedPayments,
      },
      {
        path: "/property-managers",
        name: "property managers",
        component: PropertyManagers,
      },

      {
        path: "/property-manager/:id",
        name: "Property Manager Details",
        component: PropertyManagerDetails,
      },
      {
        path: "/landlord/:id",
        name: "Landlord Details",
        component: LandlordView,
      },
      {
        path: "/manager/accounting/reports",
        name: "Accounting Report",
        component: AccountingReport,
      },
      {
        path: "/manager/tenant/statement",
        name: "Tenant Statement",
        component: TenantStatement,
      },
      {
        path: "/manager/payments/reports",
        name: "Payments Report",
        component: PaymentsReport,
      },
      {
        path: "/manager/lease/reports",
        name: "Lease Report",
        component: LeaseReport,
      },
      {
        path: "/roles",
        name: "Roles",
        component: Roles,
      },
      {
        path: "/roles/form",
        name: "Roles Form",
        component: RolesForm,
        props: true,
      },
      {
        path: "/communications",
        name: "Communications",
        component: Communications,
      },
      {
        path: "/business-income",
        name: "Business Income",
        component: BusinessIncome,
      },
      {
        path: "/business-expenses",
        name: "Business Expense",
        component: BusinessExpense,
      },
      {
        path: "/invoices",
        name: "Monthly Invoices",
        component: Invoices,
      },
      {
        path: "/admin-invoices",
        name: "Admin Invoices",
        component: AdminInvoices,
      },
       {
        path: "/admin-invoices-payments",
        name: "Admin Invoices Payments",
        component: AdminInvoicesPayments,
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
      },
      {
        path: "/service-request",
        name: "Service Request",
        component: ServiceRequest,
      },
      {
        path: "/manager-stats",
        name: "Current Statistics",
        component: StatisticalCharts,
      },
      {
        path: "/manager-logs",
        name: "Logs",
        component: Logs,
      },
      {
        path: "/blast-tenant-password/:property_id",
        name: "Tenants Password ",
        component: SmsPasswordTable,
      },
      {
        path: "/blast-composed-messages/:property_id",
        name: "Compose Message",
        component: ComposeBlastSms,
      },
      {
        path: "/blast-invoices-message/:property_id",
        name: "Blast Invoices",
        component: BlastInvoiceSms,
      },
      {
        path: "/blast-water-bill-message/:property_id",
        name: "Blast Water Bills",
        component: WaterBillSms,
      },
      {
        path: "/schedule-message/:property_id",
        name: "Schedule Message",
        component: ScheduledSmsForm,
      },
      {
        path: "/closed-leases",
        name: "Closed Leases",
        component: ClosedLeases,
      },
      {
        path: "/landlord-closed-leases",
        name: "Landlord Closed Leases",
        component: LandlordClosedLeases,
      },
      {
        path: "/closed-lease/:closedLeaseId",
        name: "Closed Lease",
        component: ViewClosedLease,
      },
      {
        path: "/properties",
        name: "Properties",
        component: Properties,
      },
      {
        path: "/property/:id/details",
        name: "More On Property",
        component: ViewPropertyDrawer,
      },
      {
        path: "/manager/payments",
        name: "Manager Payments",
        component: ManagerPayments,
      },
      {
        path: "/payments/unallocated",
        name: "Unallocated Payments",
        component: UnallocatedPayments,
      },
      {
        path: "/accounts/invoices",
        name: "Invoices",
        component: Invoices,
      },
      {
        path: "/accounts/payments",
        name: "Payments",
        component: Invoices,
      },
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
      },
      {
        path: "/outgoing-sms",
        name: "OutgoingSms",
        component: OutgoingSms,
      },
      {
        path: "/scheduled-sms",
        name: "ScheduledSms",
        component: ScheduledSms,
      },
      {
        path: "/expected-amount/:id",
        name: "Total Expected Amount",
        component: TotalExpectedAmount,
      },
      {
        path: "/etims",
        name: "eTims",
        component: ETims,
      },
      {
        path: "/rent-invoice/:property_id",
        name: "Rent Invoice",
        component: RentInvoiceTable,
      },
      {
        path: "/payment-invoice/:property_id",
        name: "Payment Invoice",
        component: PaymentInvoiceTable,
      },
      {
        path: "/view-report",
        name: "View Accounting Report",
        component: ReportView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    const tokenExpiration = localStorage.getItem("tokenExpiration");

    if (token && user && isAuthenticated) {
      const currentTime = Math.floor(Date.now() / 1000);
      if (tokenExpiration && currentTime > tokenExpiration) {
        next("/signin");
      } else {
        next();
      }
    } else {
      next("/signin");
    }
  } else {
    next();
  }
});

export default router;
