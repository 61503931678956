<template>
    <div class="modal-overlay" @click="$emit('close-settings-modal')">
      <div class="card" :style="{ 'max-height': props.settings.label === 'Penalty Rate' ? '430px' : '300px' }" @click.stop>
        <div class="card-header">
          <i class="fa fa-pencil-square-o text-primary me-2" aria-hidden="true"></i> Update {{ props.settings.label }}
        </div>
        <hr class="horizontal dark m-0" />
  
        <el-form
          ref="settingsFormRef"
          :model="settingsForm"
          :rules="rules"
          label-width="120px"
          size=""
          status-icon
          :scroll-to-error="true"
          label-position="top"
          :hide-required-asterisk="true"
        >

          <div class="card-body">
            <div class="mb-3">
              <el-form-item :label="props.settings.label" prop="value">
                <el-input v-model="settingsForm.value" />
              </el-form-item>

              <el-form-item
                  v-if="props.settings.label === 'Penalty Rate'"
                  label-width="40%"
                  label="Type"
                  prop="penalty_rate_type"
              >

                <el-radio-group v-model="settingsForm.penalty_rate_type">
                  <el-radio
                        v-for="(penalty_rate_type, index) in [
                    { value: 'percentage', label: 'Percentage' },
                    { value: 'fixed', label: 'Fixed' },
                  ]"
                      :key="index"
                      :label="penalty_rate_type.value"
                      size="large"
                      border
                  >{{ penalty_rate_type.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item
                  v-if="props.settings.label === 'Penalty Rate'"
                  label-width="40%"
                  label="Due Date"
                  prop="due_date"
              >
                <el-input type="number" v-model="settingsForm.due_date" />
              </el-form-item>
            </div>
          </div>

          <div class="card--footer">
            <button
              class="btn mb-0 btn-danger btn-xs null null"
              @click="$emit('close-modal')"
            >
              Cancel
            </button>
            <el-button
              type="success"
              :loading="isLoading"
              @click="submitForm(settingsFormRef)"
              >Submit</el-button
            >
          </div>
        </el-form>
      </div>
      <div class="close" @click="$emit('close-modal')">
        <i class="fa fa-times" />
      </div>
    </div>
  </template>
  
  <script>
  import { reactive, ref } from "vue";
  import { ElNotification } from "element-plus";
  import {  updatePropertyItem } from "@/api/property-manager/properties";
  const settingsFormRef = ref();
  const isLoading = ref(false);
  export default {
    name: "add-settings",
    props: {
      settings: {
        type: Object,
        required: false,
      },
    },
    setup(props, { emit }) {
      const settingsForm = reactive({
        id : props?.settings?.property_id || 0,
        value: props?.settings?.value || 0,
        name: props?.settings?.name || "",
        penalty_rate_type: props?.settings?.penalty_rate_type || "",
        due_date: props?.settings?.due_date || "",
      });

      const rules = reactive({

        value: [
          {
            required: true,
            message: "Please input value",
            trigger: ["blur", "change"],
          },
        ],
        penalty_rate_type: [
          {
            required: true,
            message: "Please select penalty type",
            trigger: ["blur", "change"],
          },
        ],
        due_date: [
          {
            required: true,
            message: "Please select due date",
            trigger: ["blur", "change"],
          },
        ],
      });
  
      const submitForm = async (formEl) => {
        if (!formEl) return;
        await formEl.validate((valid, fields) => {
          if (valid) {
            isLoading.value = true;
            let body = {};
            body[settingsForm.name] = settingsForm.value;
            body['penalty_rate_type'] = settingsForm.penalty_rate_type;
            body['due_date'] = settingsForm.due_date;

            updatePropertyItem(settingsForm.id, body)
                .then((data) => {
                  if (data.success) {
                    ElNotification({
                      title: "Success",
                      message: data.message,
                      type: "success",
                    });
                    emit("refresh-settings");
                    emit("close-modal");
                  } else {
                    Object.values(data.errors).forEach((val) =>
                      ElNotification({
                        title: "Error",
                        message: val[0],
                        type: "error",
                      })
                    );
                  }
                })
                .catch((err) =>
                  ElNotification({
                    title: "Error",
                    message: err.message,
                    type: "error",
                  })
                )
                .finally(() => {
                  isLoading.value = false;
                });
          } else {
            console.log("error submit!", fields);
          }
        });
      };
      return {
        submitForm,
        rules,
        settingsForm,
        settingsFormRef,
        isLoading,
        props,
      };
    },

  };
  </script>
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    background-color: #000000da;
  }
  
  .card {
    margin-top: 5%;
    outline: 0;
    border-radius: 7px;
    width: 450px;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.15);
  }
  
  .card-body {
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .card-header {
    border-bottom: 1px solid #e9ecef;
    padding: 1.2rem !important;
  }
  
  .card--footer {
    display: flex;
    justify-content: space-between;
    padding: 0 1.2rem 1.2rem 1.2rem !important;
  }
  .close {
  margin: 5% 0 0 10px;
  cursor: pointer;
  background-color: #f4443e;
  color: #ffffff;
  border: none;
  height: 30px;
  width: 30px;
  opacity: .75;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .3s ease;
}

.close i {
  font-size: 15px;
}
  input {
    border-radius: 6px;
  }

  .demonstration{
    margin-bottom: 8px;
    line-height: 22px;
    color: var(--el-text-color-regular);
    font-size: var(--el-form-label-font-size);
  }
  </style>
  