<template>
  <div class="card">
    <div class="card-header pb-0 mb-5 d-flex justify-content-between">
    <div class="d-flex align-items-center gap-2 ">
      <i class="fa fa-list text-primary text-md opacity-10"></i>
      <h6 class="mt-2">Scheduled SMS</h6>
    </div>
    <div class="cta">
      <FilterTablesForm
          :show_scheduled_sms_table_filter="true"
          :filter_init="filter_params"
          :filterCallBack="_filterCallBack"
      />
     </div>
    </div>
       <div class="card-body">
        <div v-if="sms.data.length === 0">
      <empty-state-table />
     </div>
     <div class="table-responsive" v-else>
       <div class="card-table">
         <table class="table align-items-center">
           <thead class="sticky-top">
           <tr>
             <th
                 class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
             >
               Unit Code
             </th>

             <th
                 class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
             >
               Property Name
             </th>

            <th
                 class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
             >
               Phone Number
             </th>


             <th
                 class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
             >
               Email
             </th>

             <th
                 class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
             >
              Message
             </th>

             <th
                 class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
             >
               Status
             </th>

             <th
                 class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
             >
               Type
             </th>

             <th
                 class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
             >
               Created Date
             </th>
             <th
                   class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
               >
                 Action
               </th>
           </tr>
           </thead>
           <tbody>
            <tr v-for="(item, index) in sms.data" :key="index">

              <td class="align-middle text-center">
                <p class="text-xs font-weight-bold mb-0">{{ item.unitCode }}</p>
              </td>

              <td class="align-middle text-center">
                <p class="text-xs font-weight-bold mb-0">{{ item?.units?.property?.propertyName }}</p>
              </td>

             <td class="align-middle text-center">
               <p class="text-xs font-weight-bold mb-0">{{ item.phone }}</p>
             </td>

              <td class="align-middle text-center">
                <p class="text-xs font-weight-bold mb-0">{{ item.email }}</p>
              </td>

              <td class="align-middle text-center">
               <p class="text-xs font-weight-bold mb-0 truncate-text">{{ item.message }}</p>
             </td>

              <td class="align-middle text-center">
                  <span
                      class="badge badge-sm"
                      :class="getStatusColor(item.status)"
                  >{{ item.status }}</span
                  >
              </td>

              <td class="align-middle text-center">
                <p class="text-xs font-weight-bold mb-0">{{ item?.type }}</p>
              </td>

              <td class="align-middle text-center">
                  <span class="text-secondary text-xs font-weight-bold">{{
                      dateTime(item.created_at)
                    }}</span>
              </td>
             <td class="align-middle text-center">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      <i class="fa fa-ellipsis-v text-primary"></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                            class="text-secondary font-weight-bold text-xs"
                            @click="viewSms(item.message)"
                        ><i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                          View
                        </el-dropdown-item>

                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
               </td>
           </tr>
             
           </tbody>
         </table>
       </div>
     </div>
     <div class="card-footer d-flex justify-content-center mb-3">
    <pagination
        :totalPages="paginate.total_pages"
        :perPage="paginate.per_page"
        :currentPage="paginate.current_page"
        @pagechanged="onPageChange"
      />
       <!-- <pg v-show="total_records>0" :total="total_records" :page.sync="paginate.current_page" :limit.sync="paginate.per_page" @pagination="refresh" /> -->
     </div>
   </div>
  </div>
  <ScheduledSmsForm
    v-if="showModal"
    @close-modal="showModal = false"
    :sms="smsMessage"
  />
 
</template>
<script  >
import ScheduledSmsForm from "../../../components/Modals/property-manager/ScheduledSmsForm.vue";
import Pagination from "./Pagination.vue";
import EmptyStateTable from "../../components/EmptyTableState.vue";
import { ref, reactive } from "vue";
import moment from "moment";
//  import Pg from "../Pagination.vue"
import FilterTablesForm from "./FilterTablesForm.vue";
const filter_params = ref({});
const date = ref('')
const showModal = ref(false);
const smsMessage = ref('')
export default {
name: "scheduled-sms-table",
props: {
  sms: {
    type: Object,
    required: true,
  },
  refresh: {
    type: Function,
    required: true,
  }
},

components: {
  Pagination,
  EmptyStateTable,
  FilterTablesForm,
  ScheduledSmsForm,
  // Pg
},

setup(props){
const total_records = props.sms?.total || 0;
console.log('logs total  is', props.sms.total)
const per_page = props.sms?.per_page || 0;
const total_pages = ref(0);
if (per_page > 0 && total_records > 0) {
  total_pages.value = Math.ceil(total_records / per_page);
}

const paginate = reactive({
  current_page: props.sms?.current_page || 1,
  total_pages: total_pages,
  per_page: per_page,
});
const onPageChange = (page) => {
      // eslint-disable-next-line prettier/prettier
        props.refresh(Filter, page);
};
const Filter = reactive({
      searchquery: props.searchQuery != null ? props.searchQuery : "",
    });

const getInitials = (name) => {
    if (name) {
      const nameSplit = name?.split(" ");
      const firstInitials = nameSplit[0]?.charAt(0);
      const secondInitials = nameSplit[1]?.charAt(0);

      return (
          (firstInitials ? firstInitials : "") +
          (secondInitials ? secondInitials : "")
      );
    }
    return "";
  };

const dateTime = (value) => {
  return moment(value).format("Do MMM YYYY HH:mm:ss");
};
const _filterCallBack = (filter_data) => {
    filter_params.value = filter_data;
    props.refresh(filter_params, 0);
  };

const viewSms = (message) => {
    smsMessage.value = message;
    showModal.value = true;
  };
  
  const getStatusColor = (status) => {
      switch (status) {
        case 'sent':
          return "bg-gradient-success";
        case 'pending':
          return "bg-gradient-info";

        default:
          return null;
      }
    };  
return {
    _filterCallBack,
    filter_params,
    onPageChange,
    paginate,
    getInitials,
    dateTime,
    getStatusColor,
    date,
    showModal,
    smsMessage,
    viewSms,
    total_records
  }
},

}
</script>

<style scoped>
.card-header {
display: flex;
align-items: center;
justify-content: space-between;
gap: 7px;
}
.card-table {
max-height: 60vh;
overflow-x: auto;
overflow-y: auto;
}
h6 {
margin: 0;
}

.truncate-text {
  overflow: hidden;
  text-overflow: ellipsis; 
  white-space: nowrap; 
  max-width: 150px; 
  display: inline-block; 
}
.sticky-top {
position: sticky;
top: 0;
z-index: 2;
background-color: #5f924d;
}
.sticky-top th {
color: #fff !important;
}
</style>
