<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex align-items-center gap-2">
              <i class="fa fa-paper-plane text-primary text-md opacity-10"></i>
              <h6>Schedule Message</h6>
            </div>
          </div>
          <div class="col-md-12">
            <div class="card-body">
              <p class="text-uppercase text-sm">Property Information</p>

              <div class="row">
                <div class="col-md-6 with-vertical-line">
                  <dl>
                    <dt v-if="property?.propertyName">Property Name</dt>
                    <dd v-if="property?.propertyName">{{ capitalized(property?.propertyName) }}</dd>
                    <dt v-if="property?.propertyAddress">Property Location</dt>
                    <dd v-if="property?.propertyAddress">{{ capitalized(property?.propertyAddress) }}</dd>
                  </dl>
                </div>
                <div class="col-md-5">
                  <dl>
                    <dt  v-if="property?.landlords?.name">Landlord Name</dt>
                    <dd  v-if="property?.landlords?.name">{{ capitalized(property.landlords.name) }}</dd>
                    <dt v-if="property?.lastUpdated">Created Date</dt>
                    <dd v-if="property?.lastUpdated">{{ dateTime(property.lastUpdated) }}</dd>
                  </dl>
                </div>
              </div>

              <hr class="horizontal dark" />
            </div>
          </div>
          <div v-if="isLoading"><Loader /></div>
          <div class="px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
              <div class="card-body">
                <div v-if="tenants?.length === 0">
                  <empty-state-table />
                </div>
                <div class="table-responsive" v-else>
                  <div class="card-table">
                    <table class="table align-items-center">
                      <thead class="sticky-top">
                      <tr>
                        <th
                            class="p-2 text-uppercase text-secondary text-xxs font-weight-bolder opacity-9 text-start"
                        >
                          <input type="checkbox" v-model="selectAll" />
                        </th>
                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                        >
                          Tenant Name
                        </th>
                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                        >
                          Phone Number
                        </th>
                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                        >
                          Tenant Email
                        </th>

                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                        >
                          Unit Code
                        </th>

                        <th
                            class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                        >
                          Rental Balance
                        </th>

                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in tenants" :key="item.tenantId + index">
                        <td>
                          <input
                              type="checkbox"
                              v-model="selected"
                              :value="item.tenantId"
                              number
                          />
                        </td>
                        <td>
                          <p class="text-xs font-weight-bold mb-0">{{ item?.firstName + " " + item?.lastName }}</p>
                        </td>

                        <td>
                          <p class="text-xs font-weight-bold mb-0">{{ item?.primaryPhone }}</p>
                        </td>

                        <td>
                          <p class="text-xs font-weight-bold mb-0">
                            {{ item?.email }}
                          </p>
                        </td>

                        <td>
                          <p class="text-xs font-weight-bold mb-0">
                            {{ item.leases[0]?.unit?.unitCode }}
                          </p>
                        </td>

                        <td>
                          <p class="text-xs font-weight-bold mb-0">
                            {{ item.leases[0]?.accounts[0]?.balance }}
                          </p>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3 with-vertical-line">
                      <p class="text-uppercase text-sm">Choose Time The Message To Be Sent</p>
                      <el-form
                          ref="dateFormRef"
                          :model="dateForm"
                          :rules="rules.date"
                          label-width="200px"
                          size="" status-icon
                          :scroll-to-error="true"
                          label-position="top"
                          :hide-required-asterisk="true">
                        <div class="card-body">
                          <div class="col-md-12 mb-3">
                            <el-form-item label="Pick Date And Time" prop="date">
                              <el-date-picker
                                  v-model="dateForm.date"
                                  style="width: 100%"
                                  type="datetime"
                                  placeholder="Select date and time"
                                  :shortcuts="shortcuts"
                              />
                            </el-form-item>
                          </div>
                        </div>
                      </el-form>
                    </div>
                    <div class="col-md-3 with-vertical-line">
                      <p class="text-uppercase text-sm">Format Placeholder</p>
                      <ol>
                        <li> <strong> {name}</strong><i> - This is the tenant's name </i></li>
                        <li> <strong> {property}</strong><i> - Property that tenant stays </i></li>
                        <li> <strong> {unit_code}</strong><i> - Tenant's unit code </i></li>
                      </ol>
                    </div>
                    <div class="col-md-4">
                      <p class="text-uppercase text-sm">Enter Your Message Here</p>
                      <el-form
                          ref="messageFormRef"
                          :model="messageForm"
                          :rules="rules.message"
                          label-width="200px"
                          size="" status-icon
                          :scroll-to-error="true"
                          label-position="top"
                          :hide-required-asterisk="true">
                        <div class="card-body">
                          <div class="mb-3">
                            <el-form-item label="Message" prop="message">
                              <el-input
                                  v-model="messageForm.message"
                                  placeholder="Dear {name}, of {property}, your unit code is {unit_code}"
                                  type="textarea" />
                            </el-form-item>
                          </div>
                        </div>
                      </el-form>
                    </div>
                  </div>
                  <hr class="horizontal dark" />
                </div>
              </div>
              <div class="card--footer" style="margin-top: 20px; margin-bottom: 80px">
                <el-button
                    type="success"
                    :loading="isLoading"
                    @click="submitChecked('sms')"
                >Send Sms</el-button>
                <el-button
                    type="primary"
                    :loading="isLoading"
                    @click="submitChecked('email')"
                >Send Mail</el-button>
                <el-button
                    type="danger"
                    @click="toggleSelection()"
                >Clear selection</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {ref, onMounted, reactive, computed} from 'vue';
import { fetchPropertyTenantsWithAccountBalance } from "@/api/property-manager/tenants";
import {fetchPropertyDetails} from "@/api/property-manager/properties";
import moment from "moment/moment";
import Loader from "./../../components/Loader.vue";
import { ElNotification } from "element-plus";
import { sendScheduledSMSToTenants } from "@/api/property-manager/communications";
import {useRoute, useRouter} from "vue-router";
import EmptyStateTable from "../EmptyTableState.vue";

const tenants = ref([]);
const selected = ref([]);
const property = ref([]);
const isLoading = ref(false);
export default {
  components: {
    EmptyStateTable,
    Loader
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const shortcuts = [
      {
        text: 'Today',
        value: new Date(),
      },
      {
        text: 'Tomorrow',
        value: () => {
          const date = new Date()
          date.setTime(date.getTime() + 3600 * 1000 * 24)
          return date
        },
      },
      {
        text: 'Next week',
        value: () => {
          const date = new Date()
          date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
          return date
        },
      },
    ]

    const multipleTableRef = ref();
    const dateFormRef = ref();


    const dateForm = reactive({
      date:  "",
    });

    const messageForm = reactive({
      message:  "",
    });

    const rules = reactive({
      date: [
        {
          required: true,
          message: "Please pick a date",
          trigger: ["blur", "change"],
        },
      ],
      message: [
        {
          required: true,
          message: "Please input your message",
          trigger: ["blur", "change"],
        },
      ],
    });

    const toggleSelection = () => {
      return selected.value = [];
    };

    const selectAll = computed({
      get() {
        if (tenants.value && tenants.value.length > 0) {
          let allChecked = true;
          for (const tenant of tenants.value) {
            if (!selected.value.includes(tenant.tenantId)) {
              allChecked = false;
            }
            if (!allChecked) break;
          }

          return allChecked;
        }
        return false;
      },
      set(value) {
        const checked = [];
        if (value) {
          tenants.value.forEach((tenant) => {
            checked.push(tenant.tenantId);
          });
        }

        selected.value = checked;
      },
    });

    const fetchData = async () => {
      const propertyId = route.params.property_id;

      isLoading.value = true;
      try {

        const response = await fetchPropertyTenantsWithAccountBalance(propertyId);
        const propertyInfo = await fetchPropertyDetails(propertyId);

        property.value = propertyInfo.data;
        tenants.value = response.data;

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
    });

    const capitalized = (name) => {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);
      return capitalizedFirst + rest;
    };

    const dateTime = (value) => {
      return moment(value).format("Do MMM YYYY");
    };

    const submitChecked = (mode) => {
      const date = dateForm.date;
      const message = messageForm.message;

      if (date === '') {
        ElNotification({
          title: "Error",
          message: "Please choose date",
          type: "error",
        })
        return;
      } else if (!selected.value) {
        ElNotification({
          title: "Error",
          message: "Please select tenant",
          type: "error",
        })
        return;
      }

      isLoading.value = true;
      const formData = new FormData();
      formData.append("tenantIds", JSON.stringify(selected.value));
      formData.append("dateTime", date);
      formData.append("message", message);
      formData.append("mode", mode);

      sendScheduledSMSToTenants(formData)
          .then((data) => {
            if (data.success) {
              selected.value = [];
              ElNotification({
                title: "Success",
                message: data.message,
                type: "success",
              });

              router.push('/scheduled-sms')
            }  else if(data.error){
              selected.value = [];
              ElNotification({
                title: "Error",
                message: data.error,
                type: "error",
              })
            } else {
              Object.values(data.errors).forEach((val) =>
                  ElNotification({
                    title: "Error",
                    message: val[0],
                    type: "error",
                  })
              );
            }
          })
          .catch((err) =>
              ElNotification({
                title: "Error",
                message: err.message,
                type: "error",
              })
          )
          .finally(() => {
            isLoading.value = false;
          });
    };

    return {
      shortcuts,
      rules,
      messageForm,
      dateFormRef,
      dateForm,
      isLoading,
      multipleTableRef,
      toggleSelection,
      fetchData,
      selected,
      selectAll,
      tenants,
      property,
      capitalized,
      dateTime,
      submitChecked
    };
  }
};
</script>

<style scoped>
.with-vertical-line {
  border-right: 1px solid #000;
  padding-right: 0.5rem;
  margin-right:  0.5rem;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
}
h6 {
  margin: 0;
}

.card-table {
  max-height: 65vh;
  overflow-x: auto;
  overflow-y: auto;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #5f924d;
}
.sticky-top th {
  color: #fff !important;
}


.card--footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.2rem 1.2rem 1.2rem !important;
}
</style>
